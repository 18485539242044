<script setup lang='ts'>
import { ChevronLeft, ChevronRight } from 'lucide-vue-next';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import useLocale from '@/composables/useLocale';
import useModeListData from '@/composables/useModeListData';
import useModeListFilters from '@/composables/useModeListFilters';
import useResponsive from '@/composables/useResponsive';

const slider = ref();
const { t } = useI18n();
const { currLocale } = useLocale();
const { isMobile } = useResponsive();
const { types, fetchProductsList, themesRouting, assetsURL } = useModeListData();
const { filters, saveFiltersToSessionStorage } = useModeListFilters();

const matchingTypes = computed(() => types.value.filter(it => it.isMatching).sort(it => it.isSelected ? -1 : 1));
const nonMatchingTypes = computed(() => types.value.filter(it => !it.isMatching));
const pictogramSize = computed(() => isMobile.value ? '30' : '50');

// typeLabel : ['preprodID', 'prodID']
const assetsListing: { [key: string]: string[] } = {
  boncadeau: ['4a4b6a8c-dc8d-4d75-88f1-53286e8c29da', '2e856e0f-c74c-42f0-8bc2-4ffeeaf42fbf'],
  excursionbateau: ['a38742bb-fefa-4eaf-984a-b7e9abfd3e62', '48eb0dfe-684b-4f85-9971-0d73d0482be4'],
  visiteguidee: ['76fff7a3-6701-4b9f-abe4-b076b779f319', 'ba08a6e2-63ee-412c-bbcf-1eb627b672e1'],
  excursion: ['14563e96-1d05-4d75-82d5-4928edf7570e', '600fbcb2-0e2f-47d9-9013-319eb8bee831'],
  circuit: ['14563e96-1d05-4d75-82d5-4928edf7570e', '0856333b-1ff0-4302-8647-e7392ca102e1'],
  citytour: ['df6b3136-6792-43cf-9ac0-2e9faf46b721', 'dee6647a-3211-491f-87c4-e9b38b88694a'],
  degustation: ['0c537b95-6c82-4786-a414-692ce2a93217', 'bb13b27a-c18f-40e7-97f6-c59e919a8e2d'],
  velo: ['0a83c923-20ca-4e5a-bf0b-ff6bfed07489', '42838a0e-a1c8-4bbf-8c63-f7ba434b123a'],
  piste: ['cd01f3cb-761d-4a6d-9fe0-aec31fb90fc9', '4f692ccf-bb73-44e8-8fb7-d43c40a6ae81'],
  randonnee: ['e7d377ca-8da5-4e5d-946f-7da096bece4e', '36507ff1-8457-464d-bc9d-8fdc70a1f376'],
  escalade: ['aeb512f7-6f64-4988-881b-eac4e08b1b99', 'e7152e49-cdf2-4f13-8ec5-d5aba2180c9e'],
  sidecar: ['de2d917b-c89a-4aac-a400-c151efc69631', '1746c143-2749-4875-830e-78905a2a372c'],
  tuktuk: ['cbba9497-d966-46dd-9684-68c3e9dba44a', 'c15b7a53-2a8a-4d74-8164-6fa329d7d6a7'],
  expositions: ['20438ff9-f65f-4f59-a289-ee3841bc518d', '601d3127-c19d-4ee9-8809-6113b801a850'],
  kayak: ['380a4302-1e9b-421b-bca7-9452f29ed21b', '7761de6d-732d-40d8-96c5-ed7c2ddda5ed'],
  vignobles: ['0c537b95-6c82-4786-a414-692ce2a93217', 'ae3cf5bd-4117-40db-816f-b6911a004bee'],
  foodtour: ['0c537b95-6c82-4786-a414-692ce2a93217', 'a0d38fa8-cb57-4795-bea2-3b6aa076af3e'],
  catamaran: ['63945e41-7931-4361-801e-9f22e87a5771', 'bb8c3429-b001-4880-8186-1b9e5b1edfb6'],
  speleologie: ['b55058a0-8a49-4f52-9956-439bd5b8fbb0', '3afc2dec-a157-414b-b606-98e352c2337c'],
  musee: ['b9970c2d-5bbf-4723-97bc-48d47bb6a750', '5c3e91d5-8244-4e65-bd3f-ad2a5cd7b9c6'],
  atelier: ['d999e3fd-c959-4b49-88e9-ba3a1d787cda', 'ff8cd56e-5ee6-429a-a1f0-1028361e1e6b'],
  paddle: ['0d1f2c71-da6c-43d7-8acb-b5055cd84897', '832d6463-41d8-4bc3-bf9a-2b984e37e653'],
  traintouristique: ['d0e1f83b-55f6-4eb2-971e-b14c4b69cf1b', '2333482e-74ca-473c-bb7c-4ffd19f2139e'],
  scooter: ['c91c31fa-5034-4248-8840-85689d406ec0', '2c2b7767-3434-4ae7-8f44-159d97a2d9b9'],
  trotinette: ['f7eef7c9-7250-470d-9098-5d4a6c6cc568', '93adc0db-fad7-484d-8bcb-2ed5bbfcbec3'],
  citypass: ['b9ef4fae-dccf-4b5a-8d3f-009c03714de7', 'b240f6a1-4199-4f2a-8011-6027e392aaed'],
  bustouristique: ['beae248f-f94f-4848-99c3-f09c5b8a10eb', 'c6ad66ea-60be-4cd5-ab15-510c84c212e5'],
};

function getMatchingPicto(typeId: string): string {
  const matchingKey = Object.keys(assetsListing).find((key: string) => assetsListing[key].includes(typeId));
  if (matchingKey) {
    return `${assetsListing[matchingKey][0]}.svg`;
  }
  else {
    return 'default.svg';
  }
}

function getTranslatedName(type: any) {
  return type.translatedName.find((it: any) => it.lang === currLocale.value)?.value ?? type.name;
}

function handleSlider(side: 'left' | 'right') {
  if (!slider.value)
    return;
  const distance = Math.floor(slider.value.clientWidth / 3);
  side === 'left' ? slider.value.scrollLeft -= distance : slider.value.scrollLeft += distance;
}

function setupTypes(type: any) {
  const currentSelection = filters.value.selections?.find(it => it.filterProperties.type === 'taxonomyTypes');
  if (type.isSelected && type.isMatching) {
    const typeFilterIndex = currentSelection?.selection?.findIndex((it: any) => it === type.id);
    if (typeFilterIndex > -1) {
      currentSelection!.selection = currentSelection!.selection.filter((it: any) => it !== type.id);
    }
    if (!currentSelection.selection.length) {
      filters.value.selections = filters.value.selections.filter((it: any) => it.filterProperties.type !== 'taxonomyTypes');
    }
  }

  if (!type.isSelected && type.isMatching) {
    if (currentSelection) {
      currentSelection.selection.push(type.id);
    }
    else {
      filters.value.selections?.push({
        filterProperties: {
          type: 'taxonomyTypes',
        },
        selection: [type.id],
      });
    }
  }

  if (!type.isMatching) {
    if (currentSelection) {
      currentSelection.selection = [type.id];
    }
    else {
      filters.value.selections?.push({
        filterProperties: {
          type: 'taxonomyTypes',
        },
        selection: [type.id],
      });
    }
    filters.value.selections = filters.value.selections.filter((it: any) => it.filterProperties.type !== 'taxonomyThemes');
    filters.value.metrics = filters.value.metrics.filter((it: any) => it.type !== 'crossedTaxonomyCount');
  }
  // Reset du search
  filters.value.sorts = filters.value.sorts.filter((it: any) => it.type !== 'search');

  type.isSelected = !type.isSelected;
  saveFiltersToSessionStorage();

  const defaultRoute = themesRouting.value.find(it => it.taxonomies?.length === 0);
  const selectedThemes = filters.value.selections.find((it: any) => it.filterProperties.type === 'taxonomyThemes')?.selection ?? [];
  const selectedTypes = filters.value.selections.find((it: any) => it.filterProperties.type === 'taxonomyTypes')?.selection ?? [];
  const routingConditions: any = [];
  if (selectedThemes.length > 0)
    routingConditions.push({ type: 'THEMES', value: selectedThemes });
  if (selectedTypes.length > 0)
    routingConditions.push({ type: 'TYPES', value: selectedTypes });

  const specificRoute = themesRouting.value.find(route =>
    JSON.stringify(route.taxonomies) === JSON.stringify(routingConditions) || JSON.stringify(route.taxonomies) === JSON.stringify(routingConditions.reverse()),
  );

  if (specificRoute && specificRoute.url !== window.location.href) {
    window.location.href = specificRoute.url;
  }
  else if (!specificRoute && defaultRoute && window.location.href !== defaultRoute.url) {
    window.location.href = defaultRoute.url;
  }
  else {
    fetchProductsList(filters.value);
  }
}

// DELETED IN RAPID QA BEFORE PUSH TO PROD. TO CONFIRM
// watch(
//   () => types.value,
//   () => slider.value.scrollTo({
//         left: 0,
//         behavior: 'smooth'
//     }),
//     { deep: true }
// )
</script>

<template>
  <section :aria-label="t('activities.label')">
    <div class="activities-wrapper">
      <button v-if="types.length > 0" type="button" class="arrow-left" :aria-label="t('activities.left')" @click="handleSlider('left')">
        <ChevronLeft :size="26" color="var(--yb-color-black)" aria-hidden="true" />
      </button>
      <div ref="slider" class="slider">
        <a v-for="type in matchingTypes" :key="type.id" role="link" class="activity-block" :class="{ matching: type.isMatching, selected: type.isSelected }" tabindex="0" :aria-label="type.name" @click="setupTypes(type)" @keydown.enter="setupTypes(type)">
          <img :src="`${assetsURL}/${getMatchingPicto(type.id)}`" :height="pictogramSize" :width="pictogramSize" alt="pictogramme de l'activité" aria-hidden="true">
          <h3>{{ getTranslatedName(type) }}<span style="margin-left: 4px;">({{ type.count }})</span></h3>
        </a>
        <div v-if="matchingTypes.length && nonMatchingTypes.length" class="slider_separator" />
        <a v-for="type in nonMatchingTypes" :key="type.id" role="link" class="activity-block" :class="{ matching: type.isMatching, selected: type.isSelected }" tabindex="0" :aria-label="type.name" @click="setupTypes(type)" @keydown.enter="setupTypes(type)">
          <img :src="`${assetsURL}/${getMatchingPicto(type.id)}`" :height="pictogramSize" :width="pictogramSize" alt="pictogramme de l'activité" aria-hidden="true">
          <h3>{{ getTranslatedName(type) }}<span style="margin-left: 4px;">({{ type.count }})</span></h3>
        </a>
      </div>
      <button v-if="types.length > 0" type="button" class="arrow-right" :aria-label="t('activities.right')" @click="handleSlider('right')">
        <ChevronRight :size="26" color="var(--yb-color-black)" aria-hidden="true" />
      </button>
    </div>
  </section>
</template>

<style lang="css" scoped>
section {
  min-width: 100%;
  display: flex;
  align-items: center;
  height: 88px;
  background-color: var(--yb-color-slidergray);
  border-block: 1px solid var(--yb-color-lightgray);

  @media screen and (width < 1024px) {
    height: 66px;
  }

  .activities-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    padding-inline: 30px;
    height: 100%;
    width: 100%;

    @media screen and (width < 1024px) {
      padding-inline: 0;
    }

    .arrow-left,
    .arrow-right {
      display: grid;
      place-items: center;
      aspect-ratio: 1;
      background-color: var(--yb-color-white);
      border: 1px solid var(--yb-color-mediumgray);
      padding: var(--yb-spacing-xs);
      border-radius: 9999%;
      cursor: pointer;
      transition: box-shadow 0.2s ease-out;

      &:focus,
      &:focus-visible {
        outline-color: var(--yb-outline);
      }

      &:hover {
        box-shadow:
          rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
          rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
      }

      @media screen and (width < 1024px) {
        display: none;
      }
    }

    .arrow-left svg {
      position: relative;
      right: 1px;
    }

    .arrow-right svg {
      position: relative;
      left: 1px;
    }

    .slider {
      flex-grow: 1;
      display: flex;
      align-items: center;
      gap: var(--yb-spacing-2xl);
      width: min(100%, 1270px);
      min-height: 60px;
      height: 100%;
      margin-inline: var(--yb-spacing-base);
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      scrollbar-width: none !important;
      scrollbar-color: var(--yb-color-slidergray) var(--yb-color-slidergray);
      scroll-behavior: smooth;

      /* @media screen and (width < 1024px) {
        padding-top: var(--yb-spacing-xs);
      } */

      .slider_separator {
        height: 50px;
        min-width: 1px;
        background-color: var(--yb-color-mediumgray);
      }

      .activity-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        color: var(--yb-color-black);
        scroll-snap-align: center;
        cursor: pointer;

        &:focus,
        &:focus-visible {
          outline-color: var(--yb-outline);
          outline-offset: 6px;
        }

        svg {
          pointer-events: none;
          stroke: var(--yb-color-mediumgray);
          stroke-width: 1px;
        }

        img {
          opacity: 0.4;
        }

        h3 {
          position: relative;
          color: var(--yb-color-mediumgray);
          font-size: var(--yb-text-sm);
          font-weight: var(--yb-text-light);
          white-space: nowrap;
          pointer-events: none;
        }

        &.matching {
          img {
            opacity: 1;
          }

          h3 {
            color: var(--yb-color-black);
          }
        }

        &.selected {
          h3::after {
            position: absolute;
            content: '';
            bottom: -11px;
            left: 0;
            width: 100%;
            height: 3px;
            background-color: var(--yb-color-primary);
          }

          img {
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
