<script setup lang="ts">
import TheSlider from '@/components/TheSlider.vue';
import { computed, getCurrentInstance, h, onBeforeMount, provide, ref } from 'vue';
import { createI18n, I18nInjectionKey } from 'vue-i18n';

import useLocale from '@/composables/useLocale';
import de from '@/locales/de';
import en from '@/locales/en';
import es from '@/locales/es';
import fr from '@/locales/fr';

const props = defineProps<{ locale?: 'en-GB' | 'fr-FR' | 'es-ES' | 'de-DE' }>();
const { currLocale } = useLocale();

const $root = ref<HTMLElement>();
const css = ref('');
const blockCLS = ref(true);
const renderStyle = computed(() => {
  return h('style', css.value);
});

const timeout = 0;
function updateStyles() {
  blockCLS.value = true;
  const selector = process.env.PROD ? '[data-source="myapp"]' : 'style';
  clearTimeout(timeout);
  setTimeout(() => {
    const styles = Array.from(document.querySelectorAll(selector));
    css.value = styles
      .map(it => it.textContent ?? '')
      .join('');
    blockCLS.value = false;
  }, 25);
}

// setupCurrentLocale(props.locale ?? currLocale.value ?? 'fr-FR');
const i18n = createI18n({
  legacy: false, // must set to `false` for composition API
  locale: props.locale ?? currLocale.value ?? 'fr-FR',
  fallbackLocale: 'fr-FR',
  messages: {
    en,
    fr,
    de,
    es,
  },
});
i18n.install(getCurrentInstance()!.appContext.app);
provide(I18nInjectionKey, i18n);

onBeforeMount(async () => {
  updateStyles();
});
</script>

<template>
  <TheSlider v-if="!blockCLS" ref="$root" />
  <renderStyle />
</template>

<style>
@import '@/assets/globals.css';
</style>
